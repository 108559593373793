import { ApisauceInstance, create } from "apisauce";
import { getGeneralApiProblem } from "./api-problem";
import { ApiConfig, DEFAULT_API_CONFIG } from "./api-config";
import {
  SalesResult,
  GetSellerCategoriesParam,
  SellerCategoryResult,
  GetSellerProfileResult,
  PostRequestOTPParams,
  PostVerifyOTPParams,
  AdminFeeResponse,
  GetOrdersParam,
  GetProductsParam,
  OrderResponse,
  OrderResult,
  PlaceDetailsResponse,
  PlaceIdResponse,
  PlaceSuggestionsResponse,
  ProductResult,
  CreateOrderParams,
  GetSalesListParams,
  BuyerAdminFeeResponse,
  GetVouchersParams,
  GetVouchersResponse,
  CheckVoucherParams,
  VoucherObject,
  BuyerAccountObject,
  GetCoverageAreaParams,
  GetResellerDistrictsParams,
  ResellerCityObject,
  ResellerDistrictObject,
  GetResellerProductsParam,
  CoverageAreaObject,
  GetResellerCategoriesParam,
  DeliveryFeeObject,
  CreateOrderResponse,
  OrderVoucherCountObject,
  GetOrganicStatusResponse,
  GetBuyerAccountParams,
  GetResellerProductParam,
  RefreshTokenResponse,
} from "../../utils/interfaces";
import {
  ACCESS_TOKEN,
  clearAccessTokenCookie,
  getAccessTokenCookie,
  getRefreshTokenCookie,
  REFRESH_TOKEN,
  setCookie,
} from "../../utils/cookie";
import Bugsnag from "../bugsnag";
import { UserData } from "../../stores/account-store/account-store.model";
import { USER_DATA_KEY } from "../../utils/constants";
import { load } from "../../utils/storage";

export const getTokenFromCookie = () => {
  const cookieAuth = getAccessTokenCookie();
  if (cookieAuth) {
    try {
      return { "x-access-token": cookieAuth };
    } catch (e) {
      console.error("error getting token", e);
      // window.location.href = '/'
      return null;
    }
  }
  return null;
};

export const getTokenFromServerCookie = (context) => {
  const cookieAuth: string = context.req.cookies[ACCESS_TOKEN];
  if (cookieAuth) {
    try {
      return { "x-access-token": cookieAuth.replaceAll('"', "") };
    } catch (e) {
      console.error("error getting token", e);
      // window.location.href = '/'
      return null;
    }
  }
  return null;
};

async function checkResponse(response: any) {
  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    if (problem) {
      const problemWithUrl = {
        ...problem,
        url: response?.config?.url || "",
        method: response?.config?.method || "",
      };
      if (problem.kind === "forbidden") {
        localStorage.clear();
        // clearAccessTokenCookie()
        window.location.reload();
      }
      // return also response url
      throw problemWithUrl;
    }
  }
  return response.data;
}

/**
 * Manages all requests to the API.
 */
export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauce: ApisauceInstance;

  /**
   * Configurable options.
   */
  config: ApiConfig;

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup() {
    // construct the apisauce instance
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: "application/json",
      },
    });
  }

  async refreshAccessToken() {
    try {
      const refreshToken = getRefreshTokenCookie();
      if (refreshToken) {
        const response: RefreshTokenResponse = await this.postData(
          "/auth/buyer/refresh-token",
          { refresh_token: refreshToken }
        );
        if (response?.token) {
          setCookie(ACCESS_TOKEN, response.token, response.expired_at);
          setCookie(REFRESH_TOKEN, response.refresh_token, response.expired_at);
        }
      }
    } catch (error) {
      clearAccessTokenCookie();
      window.location.reload();
    }
  }

  async sanitizeFetchResponse(response: Response) {
    const { status } = response;
    switch (status) {
      case 403:
        this.refreshAccessToken();
        break;
      default:
        return response?.json();
    }
  }

  /**
   * =====================================================================
   * Request methods:
   * GET, POST, PUT, DELETE
   * =====================================================================
   */

  async getData(
    url: string,
    params?: any,
    isUsingHeaders = true
  ): Promise<any> {
    try {
      let headers = {};
      if (isUsingHeaders) {
        const tokenFromStorage = getTokenFromCookie();
        if (tokenFromStorage) {
          headers = getTokenFromCookie();
        }
      }
      const paramsPayload: string = params
        ? new URLSearchParams(params).toString()
        : "";
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL}${url}?${paramsPayload}`,
        { headers }
      );
      return this.sanitizeFetchResponse(response);
    } catch (error) {
      if (process.env.NEXT_PUBLIC_BUGSNAG_API_KEY)
        Bugsnag.notify(new Error(error?.message));
    }
  }

  async postData(
    url: string,
    body?: any,
    isUsingHeaders = true,
    timeout = this.config.timeout
  ): Promise<any> {
    try {
      let headers = null;
      if (isUsingHeaders) {
        headers = getTokenFromCookie();
      }
      const response = await this.apisauce.post(url, body, {
        headers,
        timeout,
      });
      return await checkResponse(response);
    } catch (error) {
      if (process.env.NEXT_PUBLIC_BUGSNAG_API_KEY)
        Bugsnag.notify(new Error(error?.message));
      throw error;
    }
  }

  async putData(url: string, body?: any): Promise<any> {
    try {
      const headers = getTokenFromCookie();
      const response = await this.apisauce.put(url, body, { headers });
      return await checkResponse(response);
    } catch (error) {
      if (process.env.NEXT_PUBLIC_BUGSNAG_API_KEY)
        Bugsnag.notify(new Error(error?.message));
      throw error;
    }
  }

  async deleteData(url: string, params?: any, body?: any): Promise<any> {
    try {
      const headers = getTokenFromCookie();
      const response = await this.apisauce.delete(url, params, {
        headers,
        data: body,
      });
      return await checkResponse(response);
    } catch (error) {
      if (process.env.NEXT_PUBLIC_BUGSNAG_API_KEY)
        Bugsnag.notify(new Error(error?.message));
      throw error;
    }
  }

  /**
   * =====================================================================
   *                             API METHODS
   * =====================================================================
   */
  async getProducts(params?: GetProductsParam): Promise<ProductResult> {
    try {
      return await this.getData("/guest/seller-product", params);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getResellerProducts(
    params?: GetResellerProductsParam
  ): Promise<ProductResult> {
    try {
      return await this.getData("/guest/reseller-products", params);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getResellerProductDetail(
    params: GetResellerProductParam
  ): Promise<ProductResult> {
    try {
      const { id, ...rest } = params;
      return await this.getData(`/guest/reseller-product/${id}`, { ...rest });
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getResellerCategories(
    params?: GetResellerCategoriesParam
  ): Promise<SellerCategoryResult[]> {
    try {
      return await this.getData("/guest/reseller-category", params);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getSellerCategories(
    params?: GetSellerCategoriesParam
  ): Promise<SellerCategoryResult[]> {
    try {
      return await this.getData("/guest/seller-category", params);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getSellerProfile(
    params?: GetSellerCategoriesParam
  ): Promise<GetSellerProfileResult> {
    try {
      return await this.getData("/guest/seller-profile", params);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getOrders(params?: GetOrdersParam): Promise<OrderResult> {
    const mappedParams: GetOrdersParam = {
      ...(!!params.buyerId && { buyer_id: params.buyerId }),
      ...(!!params.limit && { limit: params.limit }),
      ...(!!params.offset && { offset: params.offset }),
      ...(!!params.orderNumber && { order_number: params.orderNumber }),
      ...(!!params.status && { status: params.status }),
      ...(!!params.paymentStatus && { payment_status: params.paymentStatus }),
      ...(!!params.deliveryType && { delivery_type: params.deliveryType }),
      ...(!!params.dueDate && { due_date: params.dueDate }),
      ...(!!params.startDate && { start_date: params.startDate }),
      ...(!!params.endDate && { end_date: params.endDate }),
      ...(!!params.customerName && { customer_name: params.customerName }),
      ...(!!params.search && { search: params.search }),
      ...(!!params.orderBy && { order_by: params.orderBy }),
      ...(!!params.sortBy && { sort_by: params.sortBy }),
      ...(!!params.reseller && { reseller: params.reseller }),
      ...(!!params.seller_id && { seller_id: params.seller_id }),
    };
    try {
      if (!mappedParams.search) delete mappedParams.search;
      return await this.getData("/buyer/order", mappedParams);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getOrderDetail(id: string): Promise<OrderResponse> {
    try {
      return await this.getData(`/buyer/order/${id}`);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async markOrderLogsAsRead(orderId: string) {
    try {
      return await this.postData(`/buyer/order/${orderId}/read`);
    } catch (e) {
      console.error(e);
    }
  }

  async getPlaceId(
    lat: number,
    lng: number
  ): Promise<{ results: PlaceIdResponse[]; status: string }> {
    try {
      const headers = {
        "x-api-platform": "web-dash",
      };
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/gmaps/geocode?lat=${lat}&lng=${lng}`,
        { headers }
      );
      return this.sanitizeFetchResponse(response);
    } catch (e) {
      console.error(e);
      return e;
    }
  }

  async getPlaceDetails(placeId: string): Promise<PlaceDetailsResponse> {
    try {
      const headers = {
        "x-api-platform": "web-dash",
      };
      const savedUser: UserData = await load(USER_DATA_KEY);
      const url = new URL(`${process.env.NEXT_PUBLIC_API_URL}/gmaps/place`);
      url.search = new URLSearchParams({
        place_id: placeId,
        ...(savedUser?.isTradingBuyer && {
          order_type: savedUser?.isTradingBuyer ? "trading" : "retail",
        }),
      }).toString();
      const response = await fetch(url, { headers });
      return this.sanitizeFetchResponse(response);
    } catch (e) {
      console.error(e);
      return e;
    }
  }

  async getPlaceSuggestions(
    input: string
  ): Promise<{ predictions: PlaceSuggestionsResponse[]; status: string }> {
    try {
      const headers = {
        "x-api-platform": "web-dash",
      };
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/gmaps/autocomplete?input=${input}`,
        { headers }
      );
      return this.sanitizeFetchResponse(response);
    } catch (e) {
      console.error(e);
      return e;
    }
  }

  async requestOtp(params: PostRequestOTPParams): Promise<any> {
    return await this.postData("/auth/buyer/send-otp", params);
  }

  async verifyOtp(params: PostVerifyOTPParams): Promise<any> {
    return await this.postData("/auth/buyer/verify-otp", params);
  }

  async getBuyerAccount(
    params: GetBuyerAccountParams
  ): Promise<BuyerAccountObject> {
    try {
      return await this.getData("/buyer/account", params);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getOrderVoucherCount(): Promise<OrderVoucherCountObject> {
    try {
      return await this.getData("/buyer/check-order-voucher");
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async createOrder(params: CreateOrderParams): Promise<CreateOrderResponse> {
    try {
      const response = await this.postData("/buyer/order", params);
      if (response && Array.isArray(response)) {
        return { orders: response };
      }
      return response;
    } catch (e) {
      throw Error(e?.message || "Terjadi kesalahan");
    }
  }

  async getSalesList(params: GetSalesListParams): Promise<SalesResult[]> {
    try {
      return await this.getData("/guest/sales", params);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getSellerPaymentMethods(
    username: string,
    coverageAreaId: string
  ): Promise<AdminFeeResponse[]> {
    try {
      return await this.getData("/guest/admin-fee", {
        username,
        ...(coverageAreaId && { coverage_area_id: coverageAreaId }),
      });
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getBuyerPaymentMethods(
    sellerId: string,
    coverageAreaId: string
  ): Promise<BuyerAdminFeeResponse[]> {
    try {
      return await this.getData("/buyer/payment-method", {
        seller_id: sellerId,
        ...(coverageAreaId && { coverage_area_id: coverageAreaId }),
      });
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getVouchers(params: GetVouchersParams): Promise<GetVouchersResponse> {
    return await this.getData("/buyer/voucher", params);
  }

  async checkVoucher(params: CheckVoucherParams): Promise<VoucherObject> {
    return await this.postData("/buyer/check-voucher", params);
  }

  async getResellerCities(): Promise<{
    count: number;
    rows: ResellerCityObject[];
  }> {
    return await this.getData("/guest/cities");
  }

  async getResellerDistricts(
    params: GetResellerDistrictsParams
  ): Promise<{ count: number; rows: ResellerDistrictObject[] }> {
    return await this.getData("/guest/districts", {
      ...(!!params.name && { name: params.name }),
      ...(!!params.cityId && { city_id: params.cityId }),
      ...(!!params.resellerBusinessType && {
        order_type: params.resellerBusinessType,
      }),
    });
  }

  async getCoverageArea(
    params: GetCoverageAreaParams
  ): Promise<{ coverage_areas: CoverageAreaObject[] }> {
    return await this.getData("/guest/coverage-area", params);
  }

  async getDeliveryFees(username: string): Promise<[DeliveryFeeObject]> {
    try {
      return await this.getData("/guest/delivery-fee", { username });
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getOrganicStatus(): Promise<GetOrganicStatusResponse> {
    return await this.getData("/buyer/account/organic-status");
  }

  async requestMorakOtp(orderId: string): Promise<any> {
    return await this.postData(
      `/buyer/order/${orderId}/request-otp-morak-loan`
    );
  }

  async verifyMorakOtp(orderId: string, otp: string): Promise<any> {
    return await this.postData(`/buyer/order/${orderId}/otp-morak-loan`, {
      otp,
    });
  }
}
